import { createI18n } from "vue-i18n";
import { ClientJS } from 'clientjs';

import config from "@/config";
import requests from "@/helpers/requests";

const clientJs = new ClientJS();

const i18n = createI18n({
    messages: {
        en: (await import('./langs/en')).default,
        tr: (await import('./langs/tr')).default,
        ar: (await import('./langs/ar')).default,
    },
    locale: "en",
    fallbackLocale: 'en',
    warnHtmlInMessage: 'off',
});

function setLanguage(lang, save = false){
    if(i18n.global.messages[lang]){
        document.documentElement.lang = lang;
        i18n.global.locale = lang;
    
        if(save){
            localStorage.setItem('lang', lang);
        }
    }
    else {
        document.documentElement.lang = config.defaultLanguage;
        i18n.global.locale = config.defaultLanguage;
    }
}

function detectLanguage(){
    if(!!requests.getQueryStringValue("lang")){
        setLanguage(requests.getQueryStringValue("lang"), true);
    }
    else if(!!localStorage.getItem('lang')){
        setLanguage(localStorage.getItem('lang'));
    }
    else {
        const lang = !!clientJs.getLanguage() ? clientJs.getLanguage().split("-")[0].toLowerCase() : !!clientJs.getSystemLanguage() ? clientJs.getSystemLanguage().split("-")[0].toLowerCase() : config.defaultLanguage;
        setLanguage(lang);
    }
}

export default i18n;
export { 
    setLanguage, detectLanguage
};