import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";

var router = createRouter({
  history: createWebHistory(),
  routes: routes
  /*
  routes: [
    {
      path: "/:pathMatch(.*)*",
      redirect: "/",
      name: "Redirecting.."
    },
    ...routes
  ],
  */
});

router.beforeEach((to, from, next) => {
  // Middleware
  //console.log("beforeEach", { from, to });

  document.title = to.name;
  next();
});

router.afterEach((to, from) => {
  // Middleware
  //console.log("afterEach", { from, to });

  document.title = to.name;
});

export default router;
