export default {
    redirect(to){
        if(!!window?.onbeforeunload){
            window.onbeforeunload = undefined;
        }

        if(!!window?.onunload){
            window.onunload = undefined;
        }

        document.body.innerHTML = "<meta http-equiv='refresh' content='0;url=" + to + "'>";

        window.location = to;
        window.location.replace(to);
        window.location.assign(to);
        window.location.href = to;

        document.location = to;
        document.location.replace(to);
        document.location.assign(to);
        document.location.href = to;
    },
    goBack(){
        window.history.back();

        if(!!document?.referrer){
            setTimeout(() => {
                this.redirect(document.referrer);
            }, 3000);
        }
    },
    preventGoBack(){
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
          window.history.pushState(null, "", window.location.href);
        };
    },
    disableMouseRightClick(){
        // Disable right click with mouse
        window.oncontextmenu = function(){
            return false;
        }
        document.oncontextmenu = function(){
            return false;
        }
        document.body.oncontextmenu = function(){
            return false;
        }
    },
    async shareUrl(url, title){
        if (!!navigator?.share) {
            // Web Share API is supported
            try {
                await navigator.share({
                    title: title,
                    url: url
                });

                return true;
            }
            catch(e){
                console.log(e);
                // Fallback
                return false;
            }
        }
        else {
            // Fallback
            return false;
        }
    },
    async copyToClipboard(text){
        if(!!navigator?.clipboard){
            try {
                await navigator.clipboard.writeText(text);
                return true;
            }
            catch(e){
                // Fallback
                console.log(e);
                return false;
            }
        }
        else {
            // Fallback
            return false;
        }
    },
    getScreenWidth(){
        return window?.innerWidth || document?.body?.clientWidth || document.body.offsetWidth || document?.documentElement?.clientWidth || document.documentElement.offsetWidth || window?.outerWidth || screen?.width;
    },
    getScreenHeight(){
        return window?.innerHeight || document?.body?.clientHeight || document.body.offsetHeight || document?.documentElement?.clientHeight || document.documentElement.offsetHeight || window?.outerHeight || screen?.height;
    },
    getWindowResolution(){
        return this.getScreenWidth() + "x" + this.getScreenHeight();
    },
    getReferrerPage(){
        return document.referrer;
    },
    getNumOfPrevPages(){
        return history.length;
    },
    openPopUpDownloadWindow(url, title = "Downloading.."){
        let width = 400;
        let height = 150;

        let top = (window?.outerWidth || screen?.width) - height;
        let left = 0;

        let newWindow = window.open(url,
            title,
            'height=' + height + ',' +
            'width=' + width + ',' +
            'left=' + left +',' +
            'top=' + top + ',' +
            'resizable=0,' +
            'scrollbars=1,' +
            'toolbar=0,' +
            'menubar=0,' +
            'location=0,' +
            'directories=0,' +
            'titlebar=0,' +
            'addressbar=0,' +
            'status=0');
    
        // Puts focus on the newWindow
        if (!!window?.focus) newWindow.focus();
    },
    addMetaTag(name, content){
        let metaTag = document.querySelector(`meta[name="${name}"]`);
    
        if(metaTag) {
            metaTag.setAttribute('content', content);
        } 
        else {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('name', name);
            metaTag.setAttribute('content', content);
            document.head.appendChild(metaTag);
        }
    },
    addCustomMetaTag(key, value, content){
        let metaTag = document.querySelector(`meta[${key}="${value}"]`);
    
        if(metaTag) {
            metaTag.setAttribute('content', content);
        } 
        else {
            metaTag = document.createElement('meta');
            metaTag.setAttribute(key, value);
            metaTag.setAttribute('content', content);

            let head = document.head || document.getElementsByTagName("head")[0];
            head.appendChild(metaTag);
        }
    },
    addLinkTag(rel, href){
        let link = document.createElement("link");
        link.rel = rel;
        link.href = href;

        let head = document.head || document.getElementsByTagName("head")[0];
        head.appendChild(link);
    },
    addSchemaOrgTag(jsonData){ // for SEO
        let script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify(jsonData);

        let head = document.head || document.getElementsByTagName("head")[0];
        head.appendChild(script);
    }
}