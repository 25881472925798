import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import config from "./config";
import i18n, { setLanguage, detectLanguage } from "./i18n";


const app = createApp(App);
app.use(router);
app.provide("i18n", i18n);
app.use(i18n);
app.config.globalProperties.config = config;
app.config.globalProperties.setLanguage = setLanguage;
app.config.globalProperties.detectLanguage = detectLanguage;
app.mount("#app");
