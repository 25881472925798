const config = require("../config")

module.exports = [
    {
        path: "/",
        component: () => import('@/pages/Home'),
        name: config.info.site.description,
    },
    {
        path: "/home",
        component: () => import('@/pages/Home'),
        name: "Home | " + config.info.site.description,
    },
    {
        path: "/services",
        component: () => import('@/pages/Services'),
        name: "Services | " + config.info.site.title,
    },
    {
        path: "/products",
        component: () => import('@/pages/Products'),
        name: "Products | " + config.info.site.title,
    },
    {
        path: "/about",
        component: () => import('@/pages/About'),
        name: "About | " + config.info.site.title,
    },
    {
        path: "/contact",
        component: () => import('@/pages/Contact'),
        name: "Contact | " + config.info.site.title,
    },
    {
        path: "/404",
        component: () => import('@/pages/404'),
        name: "Page Not Found | " + config.info.site.title,
    },
]