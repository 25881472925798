module.exports = {
    info: {
        socialMediaUrls: {
            facebook: "https://www.facebook.com/larissa.agro.trading",
            instagram: "https://www.instagram.com/larissa.agro.trading",
            //xTwitter: "#xtwitter",
            tiktok: "https://www.tiktok.com/@larissa.generaltradingco",
            //linkedIn: "#linkedIn",
            //youtube: "#youtube",
            whatsApp: "https://wa.me/905385977095",
            googleMaps: "https://maps.app.goo.gl/2QedmCnXxU54YHjr6"
        },
    
        socialMediaIframesSrcs: {
            news: "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flarissa.agro.trading&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId", // Facebook or Twitter Feeds
            googleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d564.7768247192198!2d34.62872644841843!3d36.79790259678226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1527f308609e4bc5%3A0x25270a62a62990d8!2s%C3%87ankaya%2C%20Atat%C3%BCrk%20Cd.%2012%2Fc%2C%2033070%20Akdeniz%2FMersin!5e0!3m2!1sen!2str!4v1701608001755!5m2!1sen!2strhttps://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d564.7768247192198!2d34.62872644841843!3d36.79790259678226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1527f308609e4bc5%3A0x25270a62a62990d8!2s%C3%87ankaya%2C%20Atat%C3%BCrk%20Cd.%2012%2Fc%2C%2033070%20Akdeniz%2FMersin!5e0!3m2!1sen!2str!4v1701608001755!5m2!1sen!2str", // google_maps_address_embed_url
        },
    
        contact: {
            emailAddress: "info@larissa-agro.com",
    
            phoneNumber: "+905398949566",
            phoneNumberTxt: "(+90) 539 8949 566",
    
            phoneNumber2: "+46733922343",
            phoneNumber2Txt: "(+46) 73 392 2343",
    
            address: "Çankaya Mah. Atatürk Cad. No: 12/10 Akdeniz, Mersin, Türkiye."
        },
    
        site: {
            websiteUrl: "https://www.larissa-agro.com",
            title: "Larissa Agro",
            description: "Larissa Agro Trading Company Ltd. High Quality Legumes.",
            author: "ITDBase.Com",
            owner: "Larissa Agro Ltd.",
            copyright: "Larissa Agro Ltd.",
            keywords: "Larissa, Agro, Trading, Company, Ltd, High, Quality, Legumes, Import, Export, Turkey, Yüksek, Kalite, Bakliyat, İthalat, İhracat, Türkiye,شركة,لاريسا,تجارة,عالية,الجودة,بقوليات,استيراد,تصدير,تركيا,مرسين",
            address: {
                street: "Çankaya Mah. Atatürk Cad.",
                city: "Akdeniz",
                region: "Mersin",
                postalCode: "33070",
                countryCode: "TR",
            }
        },
    },
    defaultLanguage: "en", // If browser detection didn't work, fallback language
}