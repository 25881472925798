<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
import { inject } from "vue";

import browser from "@/helpers/browser";

export default {
  data() {
    return {
      i18n: inject("i18n"),
    }
  },
  methods: {

  },
  beforeCreate() {
    console.log("beforeCreate");
  },
  created() {
    console.log("created");
  },
  beforeMount() {
    console.log("beforeMount");
  },
  mounted() {
    this.detectLanguage();

    // SEO STUFF
    browser.addMetaTag("description", this.config.info.site.description);
    browser.addMetaTag("author", this.config.info.site.author);
    browser.addMetaTag("owner", this.config.info.site.owner);
    browser.addMetaTag("copyright", this.config.info.site.copyright);
    browser.addMetaTag("keywords", this.config.info.site.keywords);

    browser.addMetaTag("twitter:title", this.config.info.site.title);
    browser.addMetaTag("twitter:url", this.config.info.site.websiteUrl);

    browser.addMetaTag("x:title", this.config.info.site.title);
    browser.addMetaTag("x:url", this.config.info.site.websiteUrl);

    browser.addCustomMetaTag("property", "og:site_name", this.config.info.site.title);
    browser.addCustomMetaTag("property", "og:title", this.config.info.site.title);
    browser.addCustomMetaTag("property", "og:description", this.config.info.site.description);
    browser.addCustomMetaTag("property", "og:url", this.config.info.site.websiteUrl);
    
    browser.addCustomMetaTag("itemprop", "name", this.config.info.site.title);
    browser.addCustomMetaTag("itemprop", "url", this.config.info.site.websiteUrl);

    browser.addLinkTag("canonical", this.config.info.site.websiteUrl + window.location.pathname + window.location.search);

    browser.addSchemaOrgTag({
      "@context": "http://schema.org",
      "@type": "Company",
      "name": this.config.info.site.title,
      "url": this.config.info.site.websiteUrl,
      "logo": this.config.info.site.websiteUrl + "/img/logo.jpg",
      "description": this.config.info.site.description,
      "address": {
        "@type": "PostalAddress",
        "streetAddress": this.config.info.site.address.street,
        "addressLocality": this.config.info.site.address.city,
        "addressRegion": this.config.info.site.address.region,
        "postalCode": this.config.info.site.address.postalCode,
        "addressCountry": this.config.info.site.address.countryCode
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": this.config.info.contact.phoneNumberTxt,
        "contactType": "Customer service"
      }
    });

    console.log("mounted");
  },
  beforeUpdate() {
    console.log("beforeUpdate");
  },
  updated() {
    console.log("updated");
  },
  beforeUnmount() {
    console.log("beforeUnmount");
  },
  unmounted() {
    console.log("unmounted");
  },
  errorCaptured(){
    console.log("errorCaptured");
  },
  renderTracked(){
    console.log("renderTracked");
  },
  activated(){
    console.log("activated");
  },
  deactivated(){
    console.log("deactivated");
  },
}
</script>

<style>

</style>

