export default {
    getQueryStringValue(param="", url){
        if(typeof url === 'string'){
            url = new URL(url);
        }
        else {
            url = window?.location
        }

        return (new URLSearchParams(url?.search?.substring(1)))?.get(param) || undefined;
    },
}